/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/store";
import styles from "./CreateCustomerRight.module.css";
import LabeledInput from "../../../General/LabeledInput/LabeledInput";
import "react-circular-progressbar/dist/styles.css";
//import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useHistory } from "react-router-dom";
//import { SheetJSApp } from "./CreateCustomerVinList";
import PopupDialog from "../../../General/PopupDialog/PopupDialog";
import DiscardDialog from "../../../ManageCampaign/CreateCampaign/Dialog/DiscardDialog/DiscardDialog";
import Button from "../../../General/Button/Button";
import SaveDialog from "../../../ManageCampaign/CreateCampaign/Dialog/SaveDialog/SaveDialog";
import PublishDialog from "../../../ManageCampaign/CreateCampaign/Dialog/PublishDialog/PublishDialog";
import moment from "moment";
import { CapabilityConstants } from "../../../Constants/CapabilitiyConstants";
import { CustomerListConstants } from "../../../Constants/CustomerListConstants";
import { Context } from "../../../../context";
import LabeledFileUploadBar from "../../../General/LabeledFileUploadBar/LabeledFileUploadBar";
import XLSX from "xlsx";
import { Slide, toast } from "react-toastify";
import { Msg, ErrorMsg } from "./AlertComponent";
import { ReactComponent as Success } from "../../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../../assets/icons/Close-Alert.svg";
import BackButton from "../../../General/Button/Button";
import LoadSpinner from "../../../General/LoadSpinner/LoadSpinner";

const VinList = ({ navSelected, itemsEntered, id }) => {
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  const history = useHistory();
  const pathName = history.location.pathname.split("/");
  const [name, setName] = useState();
  const [toBeCompleted, setToBeCompleted] = useState([]);
  const [description, setDescription] = useState();
  const [customerList, setCustomerList] = useState();
  const { capability, value } = useContext(Context);
  const [capabilities, setCapabilities] = capability;
  const userName = useAppSelector((state) => state.authReducer.userName);
  const [vinListFile, setVinListFile] = useState();
  const [data, setData] = useState([]);
  const [cols, setCols] = useState([]);
  const [editVinListFileName, setEditVinListFileName] = useState();
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [s3VinListFileKey, setS3VinListFileKey] = useState();
  const [context, setContext] = value;
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setCustomerList({
      name: name,
      description: description,
      vinListFile: vinListFile
    });
  }, [name, description, vinListFile]);

  useEffect(() => {
    let toBeCompletedArray = [];
    toBeCompletedArray = !name
      ? [...toBeCompletedArray, "Name"]
      : toBeCompletedArray;
    toBeCompletedArray = !description
      ? [...toBeCompletedArray, "Description"]
      : toBeCompletedArray;
    toBeCompletedArray = !vinListFile && !editVinListFileName
      ? [...toBeCompletedArray, "Vin List"]
      : toBeCompletedArray;
    setToBeCompleted(toBeCompletedArray);
  }, [name, description, vinListFile, editVinListFileName]);

  useEffect(() => {
    itemsEntered(toBeCompleted.length + 1, "import")
  }, [toBeCompleted]);

  /*get Values from database when user clicks edit in manageTemplate*/
  useEffect(() => {
    if (id !== undefined) {
      const getVinListDetails = async () => {
        setIsLoaded(true)
        const url = new URL(
          `${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/${id}`
        );
        const callParams = {
          method: "GET",
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`
          }
        };
        const serverResponse = await fetch(url.toString(), callParams);
        const serverResponseJson = serverResponse.json().then((response) => {
          const res = response.customerlists[0];
          console.log(JSON.stringify(res));
          setName(res.name);
          setDescription(res.shortDescription);
          setEditVinListFileName(res.customerListUplodedFileName);
          setS3VinListFileKey(res.s3FileKey);
        });
        setIsLoaded(false)
      };
      getVinListDetails();
    }
  }, []);

  const saveCustomerList = async (customerList) => {
    setIsLoaded(true)
    let formData = new FormData();
    // Update the formData object 
    formData.append("vinList", vinListFile);
    formData.append('name', name);
    formData.append('status', "Draft");
    formData.append('createdBy', userName);
    formData.append('description', description);
    formData.append("fileDeleteFlag", deleteFlag);
    formData.append('customerListType', "vinList");
    //customerList.status = "Draft";

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    let url, methodValue;

    if (pathName[4] === "edit") {
      url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/importvin/${id}`);
      methodValue = "PUT";
    } else {
      url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/importvin`);
      methodValue = "POST";
    }

    let callParams = {
      method: methodValue,
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        // "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
        //"X-CORRELATIONID": 0
      },
      //body: JSON.stringify(customerList)
      body: formData
    };

    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = await serverResponse.json();
    console.log("response----->" + serverResponseJson.status);
    return serverResponse.status;
  };

  const PublishCustomerList = async (customerList) => {
    const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/customerlist`)
    customerList.status = CustomerListConstants.PUBLISH;
    customerList.goLiveDate = moment().valueOf();
    let callParams = {
      method: "POST",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(customerList)
    };
    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = await serverResponse.json();
    console.log(serverResponseJson.status)
  };

  /* generate an array of column objects */
  const make_cols = (refstr) => {
    let o = [],
      C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) {
      o[i] = { name: XLSX.utils.encode_col(i), key: i };
    }
    return o;
  };

  const handleFile = async (file) => {
    //limit file size to 5 mb
    const sizeinmb = (file.size / (1024 * 1024)).toFixed(3);
    console.log("sizeinmb - ", sizeinmb);
    if (sizeinmb >= 5) {
      console.log(file + " File too Big, please select a file less than 5 mb");
      alert("File too Big, please select a file less than 5 mb");
      return;
    }

    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 1
      });
      setData(data);
      setCols(make_cols(ws["!ref"]))
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }

    setVinListFile(file);
  }

  const downloadFile = async () => {
    fetch(`${process.env.REACT_APP_API_HOSTNAME}v1/template/zip/file?fileKey=${s3VinListFileKey}`, {
      method: 'GET',
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: `Bearer ${jwt}`

      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob])
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${editVinListFileName}`
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  }

  /*reset when flag is true */
  useEffect(() => {
    if (id !== undefined) {
      if (deleteFlag === true) {
        setVinListFile("")
        setEditVinListFileName("")
      } else {
        console.log(vinListFile + "" + editVinListFileName)
      }
    } else { console.log("create vin list") }

  }, [deleteFlag])

  return (
    <div style={{ textAlign: "left" }}>
      <div className={styles.SubHeading}>Import VIN List</div>
      <div className={styles.HeadingDescription}>
        Directly import a list of VINs as an excel file.
      </div>
      <div className={pathName[4] === "view"
        ? styles.disableContainer : styles.InputContainer}>
        <div className={styles.InputFields}>
          {/* Name */}
          <LabeledInput
            title={
              <>
                Name<span>*</span>
              </>
            }
            data={name}
            className={styles.CreateCustomerCombo}
            onValueChange={(value) => setName(value)}
          />
          {/* Description */}
          <LabeledInput
            title={
              <>
                Description<span>*</span>
              </>
            }
            data={description}
            className={styles.CreateCustomerCombo}
            onValueChange={(value) => setDescription(value)}
          />
          {/* Vin List */}
          {(editVinListFileName === "" || editVinListFileName === undefined) ?
            <div style={{ marginBottom: "0.2rem" }}>
              <LabeledFileUploadBar
                title={<>Vin List<span>*</span></>}
                newUpload="no"
                onUploadClick={console.log("")}
                uploadType="uploadVIN"
                uploadedFile={(value) => {
                  handleFile(value);
                }
                }
              />
            </div> :
            <div style={{ marginBottom: "0.2rem", opacity: "0.7", pointerEvents: "none" }}>
              <LabeledFileUploadBar
                title={<>Vin List<span>*</span></>}
                newUpload="no"
                onUploadClick={console.log("")}
                uploadType="uploadVIN"
                uploadedFile={(value) => {
                  handleFile(value);
                }
                }
              />
            </div>
          }
        </div>
      </div>

      {/* Vin List Content */}
      {data.length > 0 ?
        <React.Fragment>
          <div className={styles.VinListTablePopulated}>
            <div className="table-responsive" style={{ "max-width": "111rem", "max-height": "450px", "marginTop": "0rem", "border": "1px solid #dddddd" }}>
              <table className="table table-striped">
                <tbody>
                  {data.map((r, i) => {
                    if (i <= 100) {
                      return (
                        <tr key={i} className={styles.TableRow}>
                          {cols.map((c) => (
                            <td key={c.key}>{r[c.key]}</td>
                          ))}
                        </tr>
                      );
                    } else {
                      console.log("records are not displayed");
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </React.Fragment>
        : ""}

      {/* Vin List file bundle && Delete Vin List File */}
      <div className={styles.ParentContainer}>
        <div className={styles.child1}>
          {id !== undefined && !deleteFlag ?
            <div>
              <label>
                Vin List file bundle
                <br />
                <a
                  className={pathName[4] === "view" ? styles.VinListDeleteDownloadView : styles.VinListDeleteDownload}
                  onClick={downloadFile}
                >
                  {editVinListFileName}
                </a>
              </label>
            </div> : ""}
        </div>
        <div className={styles.child1}>
          <div className={styles.DeleteVin}>
            <PopupDialog
              height="30rem"
              width="67rem"
              renderPopupContent={(closeModal) => (
                <SaveDialog
                  saveAction={async () => {
                    setDeleteFlag(true);
                    toast.success(
                      <Msg id="delete" templateName={editVinListFileName} type="file" />,
                      {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                        icon: <Success />,
                        transition: Slide,
                        closeOnClick: true
                      }
                    );
                    setContext();
                    closeModal();
                  }}
                  discardAction={closeModal}
                  className="DeleteVinListFile"
                  itemsToBeComplete={toBeCompleted}
                />
              )}
              renderControlContent={(openModal) => (
                pathName[4] !== "view" ?
                  id !== undefined && !deleteFlag ?
                    <BackButton
                      onClick={openModal}
                      name="Delete Vin List File" /> : "" : ""
              )}
            />
          </div>
        </div>
      </div>

      {/* Buttons at bottom of the page */}
      {
        pathName[4] === "view" ? (
          <div>
            <BackButton
              onClick={() => {
                localStorage.setItem("blockNavigation", false);
                history.push("/ctmp/customer-list");
              }}
              name="BtnVinListBack"
            />
          </div>
        ) : SaveAndDiscardListButtons()
      }

    </div >
  );

  function SaveAndDiscardListButtons() {
    return <div className={styles.floatcontainer}>
      {/* Discard */}
      <div className={styles.floatchild}>
        <PopupDialog
          height="30rem"
          width="67rem"
          renderPopupContent={(closeModal) => (
            <DiscardDialog
              yesAction={() => {
                history.push("/ctmp/customer-list");
              }}
              noAction={closeModal}
              className="Discard"
            />
          )}
          renderControlContent={(openModal) => (
            <Button
              onClick={openModal}
              name="Discard"
            />
          )}
        />
      </div>
      {/* save */}
      <div className={styles.floatchild3}>
        {toBeCompleted.length === 0 ?
          <div className={styles.Vinsave}>
            <Button
              onClick={async () => {
                var status = await saveCustomerList(customerList);
                (status !== 200 &&
                  toast.error(
                    <ErrorMsg id="save" templateName={name} />,
                    {
                      position: toast.POSITION.TOP_CENTER,
                      hideProgressBar: true,
                      autoClose: 3000,
                      icon: <Error />,
                      transition: Slide,
                      closeOnClick: true
                    }
                  ));
                (status === 200 &&
                  toast.success(
                    <Msg id="save" templateName={name} />,
                    {
                      position: toast.POSITION.TOP_CENTER,
                      hideProgressBar: true,
                      autoClose: 3000,
                      icon: <Success />,
                      transition: Slide,
                      closeOnClick: true
                    }
                  ));
                setIsLoaded(false)
              }
              }
              name="Save"
            />
          </div> :
          <div className={styles.Vinsave}>
            <PopupDialog
              height="30rem"
              width="67rem"
              renderPopupContent={(closeModal) => (
                <SaveDialog
                  saveAction={async () => {
                    var status = await saveCustomerList(customerList);
                    closeModal();
                    setIsLoaded(false)
                    status !== 200 &&
                      toast.error(
                        <ErrorMsg
                          id="save"
                          templateName={name}
                        />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Error />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      );
                    status = 200 &&
                      toast.success(
                        <Msg id="save" templateName={name} />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Success />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      );
                  }}
                  discardAction={closeModal}
                  itemsToBeComplete={toBeCompleted} />
              )}
              renderControlContent={(openModal) => (
                <Button
                  onClick={openModal}
                  name="Save"
                />
              )}
            />
          </div>
        }
        {/* save & close */}
        <div className={styles.Vinsaveclose} >
          {toBeCompleted.length === 0 ?
            <div>
              <Button
                onClick={async () => {
                  var status = await saveCustomerList(customerList);
                  setIsLoaded(false)
                  history.push("/ctmp/customer-list");
                  (status !== 200 &&
                    toast.error(
                      <ErrorMsg id="save" templateName={name} />,
                      {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                        icon: <Error />,
                        transition: Slide,
                        closeOnClick: true
                      }
                    ));
                  (status === 200 &&
                    toast.success(
                      <Msg id="save" templateName={name} />,
                      {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                        icon: <Success />,
                        transition: Slide,
                        closeOnClick: true
                      }
                    ));
                }}
                name="Save & Close"
              />
            </div> :
            <PopupDialog
              height="30rem"
              width="67rem"
              renderPopupContent={(closeModal) => (
                <SaveDialog
                  saveAction={async () => {
                    let status = await saveCustomerList(customerList);
                    history.push("/ctmp/customer-list");
                    closeModal();
                    setIsLoaded(false)
                    status !== 200 &&
                      toast.error(
                        <ErrorMsg
                          id="save"
                          templateName={name}
                        />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Error />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      );
                    status = 200 &&
                      toast.success(
                        <Msg id="save" templateName={name} />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Success />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      );
                  }}
                  discardAction={closeModal}
                  itemsToBeComplete={toBeCompleted} />
              )}
              renderControlContent={(openModal) => (
                <Button
                  onClick={openModal}
                  name="Save & Close"
                />
              )}
            />}
        </div>
      </div>
      {/* publish - only for edit
      {pathName[4] === "edit" ? <div className={styles.publish}>
        <PopupDialog
          height="30rem"
          width="67rem"
          renderPopupContent={(closeModal) => (
            <PublishDialog
              yesAction={async () => {
                await PublishCustomerList(customerList);
                closeModal();
                history.push("/ctmp/customer-list");
              }}
              noAction={closeModal}
              bodyText="Do you want to Publish Customer List?"
              headerText={capabilities.includes(CapabilityConstants.CUSTOMERLIST_PUBLISH) ? "Publish Message" : "No_Permissions"}
              itemsToBeComplete={toBeCompleted} />
          )}
          renderControlContent={(openModal) => (
            <Button
              onClick={openModal}
              name="Publish"
            />
          )}
        />
      </div> : ""}
    */}

    </div >
  }
};

export default VinList;
