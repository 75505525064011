import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
import styles from "./CreateContent.module.css";
import MultiPicker from "../../General/MultiPicker/MultiPicker";
import LabeledInput from "../../General/LabeledInput/LabeledInput";
import LabeledSelect from "../../General/LabeledSelect/LabeledSelect";
import DateSelector from "../../General/PopupSelector/DateSelector/DateSelector";
import SaveDialog from "../../ManageCampaign/CreateCampaign/Dialog/SaveDialog/SaveDialog";
import DiscardDialog from "../../ManageCampaign/CreateCampaign/Dialog/DiscardDialog/DiscardDialog";
import SaveAndDiscardWithPopupButtonSet from "../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
import { ReactComponent as MailIcon } from "../../../assets/icons/Mail.svg";
import { ReactComponent as Lexus } from "../../../assets/icons/lexus.svg";
import { ReactComponent as Toyota } from "../../../assets/icons/toyota.svg";
import { ReactComponent as MessageIcon } from "../../../assets/icons/Message-square.svg";
import { ReactComponent as Phone } from "../../../assets/icons/phone.svg";
import { ReactComponent as NewTag } from "../../../assets/icons/new-tag.svg";
import { ReactComponent as Volume } from "../../../assets/icons/volume.svg";
import PopupDialog from "../../General/PopupDialog/PopupDialog";
import Button from "../../General/Button/Button";
import LoadSpinner from "../../General/LoadSpinner/LoadSpinner";
import { Slide, toast } from "react-toastify";
import { Msg } from "./AlertComponent";
import { ErrorMsg } from "./AlertComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";
import { PAGE_SIZES } from "../../General/Table/TableFooter/TableFooter";

const CreateNewContent = (props) => {
  /* eslint-disable */
  const history = useHistory();
  const [createContentName, setCreateContentName] = useState();
  const [createContentDescription, setCreateContentDesription] = useState();
  const [createContentSelectTemplate, setCreateContentSelectTemplate] = useState();
  const [createContentSelectedChannels, setCreateContentSelectedChannels] = useState([]);
  const [createContentDate, setCreateContentDate] = useState();
  const [createContentBrand, setCreateContentBrand] = useState([]);
  const [createContentGeneration, setCreateContentGeneration] = useState();
  const [saveContentObject, setSaveContentObject] = useState({});
  const [toBeCompleted, setToBeCompleted] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  const userName = useAppSelector((state) => state.authReducer.userName);
  const pathName = history.location.pathname.split("/");
  const [viewMode, setViewMode] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [contentCreatedBy, setContentCreatedBy] = useState();
  const [createContentSelectCampaign, setCreateContentSelectCampaign] = useState();
  const [campaigns, setCampaigns] = useState([]);
  const [saveContentId, setSaveContentId] = useState(undefined);
  let AddCreativeBrief = false;

  /*get published templates*/
  useEffect(() => {
    const getPublishedTemplateDetails = async () => {
      setIsLoaded(true)
      const url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/template/search`
      );
      const callParams = {
        method: "POST",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
        },
        body: JSON.stringify({ "status": ["Published"] })
      };
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = await serverResponse.json();
      console.log("templates - ", serverResponseJson.templates)
      serverResponseJson.templates.map((template) => {
        setTemplates((templates) => [
          ...templates,
          { label: template.name, value: template._id }
        ]);
      });
      setIsLoaded(false)
    };
    getPublishedTemplateDetails();
  }, []);

  /*get Campaign details*/
  useEffect(() => {
    const getCampaignDetails = async () => {
      setIsLoaded(true)
      const url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/campaigns`
      );
      const callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
        },
      };
      const params = {
        pagesize: PAGE_SIZES[2]
      };
      url.search = new URLSearchParams(params).toString();

      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = await serverResponse.json();
      console.log("campaigns - ", serverResponseJson.campaigns)
      serverResponseJson.campaigns.map((campaign) => {
        setCampaigns((campaigns) => [
          ...campaigns,
          { label: campaign.name, value: campaign.id }
        ]);
      });
      setIsLoaded(false)
    };
    getCampaignDetails();
  }, []);

  /*get Values from database when user clicks edit in manageContent*/
  useEffect(() => {
    if (props.props !== undefined) {
      console.log("need api for id in newcontent page->" + props.props);
      const getContentDetails = async () => {
        setIsLoaded(true)
        const url = new URL(
          `${process.env.REACT_APP_API_HOSTNAME}v1/content/${props.props}`
        );
        const callParams = {
          method: "GET",
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`
          }
        };
        const serverResponse = await fetch(url.toString(), callParams);
        serverResponse.json().then((response) => {
          const res = response.data;
          setCreateContentName(res.name);
          setContentCreatedBy(res.createdBy);
          setCreateContentDesription(res.description);
          res.channel.map((channel) => {
            setCreateContentSelectedChannels(
              (createContentSelectedChannels) => [
                ...createContentSelectedChannels,
                channel.name
              ]
            );
          });
          res.brand.map((brand) => {
            setCreateContentBrand((createContentBrand) => [
              ...createContentBrand,
              brand.name
            ]);
          });
          setCreateContentSelectTemplate({
            label: res.template[0].name,
            value: res.template[0].id
          });
          setCreateContentGeneration({
            label: res.generation[0].name,
            value: res.generation[0].id
          });
          console.log(res.date + "from db");
          let date = new Date(res.date);
          date =
            date.getMonth() +
            1 +
            "/" +
            (date.getDate() + 1) +
            "/" +
            date.getFullYear();
          setCreateContentDate(date);
          setCreateContentSelectCampaign({
            label: res.campaign[0].name,
            value: res.campaign[0].id
          });
        });
        setIsLoaded(false)
      };
      getContentDetails();
      setEditMode(true);
      if (pathName[3] === "view-content") {
        setViewMode(true);
      } else {
        setViewMode(false)
      }
    }
  }, []);

  /*for now default value for Generation*/
  const GenerationValues = [
    {
      label: "21MM",
      value: "21MM"
    }
  ];

  /* notify if user do not enter mandatory fields */
  useEffect(() => {
    let toBeCompletedArray = [];
    toBeCompletedArray = !createContentName
      ? [...toBeCompletedArray, "Name"]
      : toBeCompletedArray;
    toBeCompletedArray = !createContentDescription
      ? [...toBeCompletedArray, "Description"]
      : toBeCompletedArray;
    toBeCompletedArray = !createContentSelectTemplate
      ? [...toBeCompletedArray, "Template"]
      : toBeCompletedArray;
    toBeCompletedArray =
      !createContentSelectedChannels || !createContentSelectedChannels.length
        ? [...toBeCompletedArray, "Channel"]
        : toBeCompletedArray;
    toBeCompletedArray = !createContentDate || createContentDate === "MM/DD/YYYY"
      ? [...toBeCompletedArray, "Due Date"]
      : toBeCompletedArray;
    toBeCompletedArray =
      !createContentBrand || !createContentBrand.length
        ? [...toBeCompletedArray, "Brand"]
        : toBeCompletedArray;
    toBeCompletedArray = !createContentGeneration
      ? [...toBeCompletedArray, "Generation"]
      : toBeCompletedArray;
    setToBeCompleted(toBeCompletedArray);
  }, [
    createContentName,
    createContentDescription,
    createContentSelectTemplate,
    createContentSelectCampaign,
    createContentSelectedChannels,
    createContentDate,
    createContentBrand,
    createContentGeneration
  ]);

  /* update the values when changed */
  useEffect(() => {
    setSaveContentObject({
      name: createContentName,
      description: createContentDescription,
      ...(createContentSelectedChannels && {
        channel: createContentSelectedChannels.map((c) => ({
          name: c
        }))
      }),
      ...(createContentBrand && {
        brand: createContentBrand.map((c) => ({
          name: c
        }))
      }),
      ...(createContentSelectTemplate && {
        template: [
          {
            name: createContentSelectTemplate.label,
            id: createContentSelectTemplate.value
          }
        ]
      }),
      ...(createContentSelectCampaign && {
        campaign: [
          {
            name: createContentSelectCampaign.label,
            id: createContentSelectCampaign.value
          }
        ]
      }),
      ...(createContentGeneration && {
        generation: [
          {
            name: createContentGeneration.label,
            id: createContentGeneration.value
          }
        ]
      }),
      createdBy: editMode === true ? contentCreatedBy : userName,
      contentCreatedBy: contentCreatedBy,
      // date: `${format(createContentDate, "MM/dd/yyyy")}`
      date: createContentDate
    });
  }, [
    createContentName,
    createContentDescription,
    createContentSelectTemplate,
    createContentSelectCampaign,
    createContentSelectedChannels,
    createContentBrand,
    createContentGeneration,
    createContentDate
  ]);

  /* save funtion */
  const saveContentData = async (saveContentObject) => {
    setIsLoaded(true)
    console.log("--->" + JSON.stringify(saveContentObject));
    console.log(saveContentId)
    let url, methodValue;
    editMode === true
      ? ((url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/content/${props.props}`
      )),
        (methodValue = "PUT"))
      : saveContentId !== undefined
        ? ((url = new URL(
          `${process.env.REACT_APP_API_HOSTNAME}v1/content/${saveContentId}`
        )),
          (methodValue = "PUT"))
        : ((url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/content`)),
          (methodValue = "POST"));

    saveContentObject.status = "Draft";

    let callParams = {
      method: methodValue,
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
        "X-CORRELATIONID": 0
      },
      body: JSON.stringify(saveContentObject)
    };
    console.log("------save content object---------->" + JSON.stringify(saveContentObject))
    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = await serverResponse.json();
    return { data: serverResponseJson.data, status: serverResponse.status }
  };

  return (
    <div style={{ textAlign: "left" }}>
      {(isLoaded === true) ? <LoadSpinner /> : ""}
      <p className={styles.UpdateFormHeader}>
        {pathName[3] === "edit-content" ? "Edit campaign content" :
          pathName[3] === "view-content" ? "View campaign content" :
            "Create new campaign content"}
      </p>
      <span className={styles.UpdateFormSubTitle}>
        From what the campaign is, what it needs to do, its function and the
        deadline, use the following sections to explain key information of the
        new campaign.
      </span>
      <div className={viewMode === true ? styles.NodeDisplayDisable : ""}>
        <div className={styles.ChannelContainer}>
          <LabeledSelect
            className={AddCreativeBrief ? styles.NodeDisplayDisable : styles.Select}
            options={campaigns}
            placeholder="Select"
            value={createContentSelectCampaign}
            defaultLabel={
              createContentSelectCampaign
                ? createContentSelectCampaign.label
                : undefined
            }
            defaultId={
              createContentSelectCampaign
                ? createContentSelectCampaign.value
                : undefined
            }
            title={<>Campaign<span></span></>}
            onValueChange={(option) => setCreateContentSelectCampaign(option)}
          />
          <label style={{ marginTop: "2rem" }}>
            Channels<span>*</span>
          </label>
          <MultiPicker
            setSelected={(selected) => setCreateContentSelectedChannels(selected)}
            previousSelected={createContentSelectedChannels}
            status="Template"
            mode={viewMode === true || editMode === true ? "" : "create-template"}
            data={[
              {
                id: 1,
                icon: MailIcon,
                title: "Email",
                description:
                  "Choose a template and send automated transactional or marketing lite emails to the selected audience."
              },
              {
                icon: MessageIcon,
                title: "Push Notification",
                description:
                  "Choose a template and send automated transactional or marketing lite push notifications to the selected audience."
              },
              {
                icon: Phone,
                title: "CT Marketing Card",
                description:
                  "Choose a template and send automated transactional or marketing lite OneApp Cards to the selected audience."
              },
              {
                icon: NewTag,
                title: "General Marketing Card",
                description:
                  "Choose a template and send automated transactional or marketing lite cards to the selected audience."
              },
              {
                icon: Volume,
                title: "Announcement Center",
                description:
                  "Choose a template and send automated transactional or marketing lite announcements to the selected audience."
              }
            ]}
          />
        </div>
        <LabeledSelect
          className={styles.Select}
          options={templates}
          placeholder="Select"
          value={createContentSelectTemplate}
          defaultLabel={
            createContentSelectTemplate
              ? createContentSelectTemplate.label
              : undefined
          }
          defaultId={
            createContentSelectTemplate
              ? createContentSelectTemplate.value
              : undefined
          }
          title={
            <>
              Template<span>*</span>
            </>
          }
          onValueChange={(option) => setCreateContentSelectTemplate(option)}
        />
        <LabeledInput
          title={
            <>
              Name<span>*</span>
            </>
          }
          data={createContentName}
          placeholder="Provide a name for this creative"
          className={styles.Input}
          onValueChange={(value) => setCreateContentName(value)}
        />
        <LabeledInput
          title={
            <>
              Description<span>*</span>
            </>
          }
          data={createContentDescription}
          className={styles.Input}
          placeholder="Add an overview of what the campaign is all about"
          onValueChange={(value) => setCreateContentDesription(value)}
        />
        <label style={{ marginBottom: "0.2rem", marginTop: "1rem" }}>
          Due Date<span>*</span>
        </label>
        <div>
          <DateSelector
            className={styles.CalendarSelector}
            onDiscard={(date) => console.log()}
            onApply={(date) => setCreateContentDate(date)}
            value={createContentDate}
          />
        </div>
        <div className={styles.ChannelContainer}>
          <label>
            Brands<span>*</span>
          </label>
          <MultiPicker
            className={styles.MultipickerStyleTemplate}
            setSelected={(selected) => setCreateContentBrand(selected)}
            previousSelected={createContentBrand}
            status="Template"
            mode={viewMode === true || editMode === true ? "" : "create-template"}
            data={[
              {
                id: 1,
                icon: Toyota,
                title: "Toyota",
                description: "Toyota branded content"
              },
              {
                icon: Lexus,
                title: "Lexus",
                description: "Lexus branded content"
              }
            ]}
          />
        </div>
        <LabeledSelect
          // optionsUrl=""
          className={styles.Select}
          value={createContentGeneration}
          defaultLabel={
            createContentGeneration ? createContentGeneration.label : undefined
          }
          defaultId={
            createContentGeneration ? createContentGeneration.value : undefined
          }
          options={GenerationValues}
          title={
            <>
              Generation<span>*</span>
            </>
          }
          onValueChange={(option) => setCreateContentGeneration(option)}
        />
      </div>

      {viewMode === true ? (
        <div>
          <div className={styles.floatchild3}>
            <Button
              onClick={() => {
                history.push("/ctmp/content");
              }}
              name="ContentBack"
            />
          </div>
          <div className={styles.floatchild4}>
            <Button
              onClick={() => {
                if (props.props !== undefined) {
                  history.push(
                    `/ctmp/content/view-content/${props.props}/creative-brief`
                  );
                }
              }}
              name="Next:Add Creative Brief"
            />
          </div>
        </div>
      ) : props === undefined ? (<div className={styles.Container}>
        <SaveAndDiscardWithPopupButtonSet
          yesPopupHeight="55rem"
          noPopupHeight="30rem"
          yesTitle="Next:Add Creative Brief"
          noTitle="Discard"
          className={styles.Buttons}
          renderDiscardPopupContent={(closeModal) => (
            <DiscardDialog
              yesAction={() => {
                setSaveContentObject({});
                history.push("/ctmp/content");
              }}
              noAction={closeModal}
            />
          )}
          renderSavePopupContent={(closeModal) => (
            <SaveDialog
              saveAction={async () => {
                let res = await saveContentData(saveContentObject);
                let id_newcontent = res.data._id;
                closeModal();
                setIsLoaded(false)
                if (props.props !== undefined) {
                  history.push(
                    `/ctmp/content/edit-content/${props.props}/creative-brief`
                  );
                } else {
                  // history.push("/ctmp/content/create-content/creative-brief")
                  history.push(
                    `/ctmp/content/create-content/creative-brief/${id_newcontent}`
                  );
                }
              }}
              discardAction={closeModal}
              itemsToBeComplete={toBeCompleted}
            />
          )}
        /></div>
      ) : (
        <div className={styles.floatcontainer}>
          <div className={styles.floatchild}>
            <PopupDialog
              height="30rem"
              width="67rem"
              renderPopupContent={(closeModal) => (
                <DiscardDialog
                  yesAction={() => {
                    history.push("/ctmp/content");
                  }}
                  noAction={closeModal}
                  className="Discard"
                />
              )}
              renderControlContent={(openModal) => (
                <Button onClick={openModal} name="Discard" />
              )}
            />
          </div>
          <div className={styles.floatchild}>
            <div className={pathName[3] === "edit-content" ? styles.floatchild6 : styles.floatchild1}>
              {/* save */}
              {toBeCompleted.length === 0 ?
                <Button
                  onClick={async () => {
                    let status_ = await saveContentData(saveContentObject);
                    console.log(status_.status)
                    let status = status_.status;
                    if (status === 200) {
                      setSaveContentId(status_.data._id)
                    }
                    status !== 200 &&
                      toast.error(
                        <ErrorMsg id="save" templateName={createContentName} />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Error />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      );
                    status === 200 &&
                      toast.success(
                        <Msg id="save" templateName={createContentName} />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Success />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      );
                    setIsLoaded(false)
                    localStorage.setItem("blockNavigation", "false")
                  }}
                  name="Save"
                />
                :
                <PopupDialog
                  height="30rem"
                  width="67rem"
                  renderPopupContent={(closeModal) => (
                    <SaveDialog
                      saveAction={async () => {
                        let res = await saveContentData(saveContentObject);
                        let status = res.status;
                        status !== 200 &&
                          toast.error(
                            <ErrorMsg id="save" templateName={createContentName} />,
                            {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                              icon: <Error />,
                              transition: Slide,
                              closeOnClick: true
                            }
                          );
                        status === 200 &&
                          toast.success(
                            <Msg id="save" templateName={createContentName} />,
                            {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                              icon: <Success />,
                              transition: Slide,
                              closeOnClick: true
                            }
                          );
                        closeModal();
                        setIsLoaded(false)
                      }}
                      discardAction={closeModal}
                      itemsToBeComplete={toBeCompleted}
                    />
                  )}
                  renderControlContent={(openModal) => (
                    <Button onClick={openModal} name="Save" />
                  )}
                />}
              {toBeCompleted.length === 0 ?
                <div style={{ display: "flex" }}>
                  <Button
                    onClick={async () => {
                      let status_ = await saveContentData(saveContentObject);
                      console.log(status_.status)
                      let status = status_.status;
                      status !== 200 &&
                        toast.error(
                          <ErrorMsg id="save" templateName={createContentName} />,
                          {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000,
                            icon: <Error />,
                            transition: Slide,
                            closeOnClick: true
                          }
                        );
                      status === 200 &&
                        toast.success(
                          <Msg id="save" templateName={createContentName} />,
                          {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000,
                            icon: <Success />,
                            transition: Slide,
                            closeOnClick: true
                          }
                        );
                      setIsLoaded(false)
                      localStorage.setItem("blockNavigation", "false")
                      history.push("/ctmp/content")
                    }}
                    name="ContentEditsave&close"
                  />
                  <Button
                    onClick={async () => {
                      let res = await saveContentData(saveContentObject);
                      let id_newcontent = res.data._id;
                      setIsLoaded(false)
                      if (props.props !== undefined) {
                        history.push(
                          `/ctmp/content/edit-content/${props.props}/creative-brief`
                        );
                      } else {
                        // history.push("/ctmp/content/create-content/creative-brief")
                        history.push(
                          `/ctmp/content/create-content/creative-brief/${id_newcontent}`
                        );
                      }
                    }}
                    name="ContentEditAddCreativeBrief"
                  /></div>
                :
                <SaveAndDiscardWithPopupButtonSet
                  yesPopupHeight="55rem"
                  noPopupHeight="30rem"
                  yesTitle="Next:Add Creative Brief" //save
                  noTitle="Save & Close" //discard
                  className={styles.ButtonsEdit}
                  renderDiscardPopupContent={(closeModal) => (
                    //save and close
                    <SaveDialog
                      saveAction={async () => {
                        let res = await saveContentData(saveContentObject);
                        let statusVar = res.status;
                        statusVar !== 200 &&
                          toast.error(
                            <ErrorMsg id="save" templateName={createContentName} />,
                            {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                              icon: <Error />,
                              transition: Slide,
                              closeOnClick: true
                            }
                          );
                        statusVar === 200 &&
                          toast.success(
                            <Msg id="save" templateName={createContentName} />,
                            {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                              icon: <Success />,
                              transition: Slide,
                              closeOnClick: true
                            }
                          );
                        closeModal();
                        history.push("/ctmp/content");
                        setIsLoaded(false)
                      }}
                      discardAction={closeModal}
                      itemsToBeComplete={toBeCompleted}
                    />
                  )}
                  renderSavePopupContent={(closeModal) => (
                    <SaveDialog
                      saveAction={async () => {
                        let res = await saveContentData(saveContentObject);
                        let id_newcontent = res.data._id;
                        closeModal();
                        setIsLoaded(false)
                        if (props.props !== undefined) {
                          history.push(
                            `/ctmp/content/edit-content/${props.props}/creative-brief`
                          );
                        } else {
                          // history.push("/ctmp/content/create-content/creative-brief")
                          history.push(
                            `/ctmp/content/create-content/creative-brief/${id_newcontent}`
                          );
                        }
                      }}
                      discardAction={closeModal}
                      itemsToBeComplete={toBeCompleted}
                    />
                  )}
                />}
            </div>
          </div>
        </div>
      )}

    </div>
  );
};
export default CreateNewContent;
