import React, { useMemo, useEffect, useContext, useState } from "react";
import { CapabilityConstants } from "../../../../Constants/CapabilitiyConstants";
import styles from "./RowActionMenu.module.css";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/store";
import { Context } from "../../../../../context";
import * as RoleStatus from './CampaignAction';
import ActionMenuItems from "./ActionMenuItems";
import ReactModal from "react-modal";
import { ReactComponent as CloseX } from "../../../../../assets/icons/Close.svg";
import { RoleConstants } from "../../../../Constants/RoleConstants";
//import LoadSpinner from "../../../../General/LoadSpinner/LoadSpinner";

import {
    STATUS_APPROVED,
    STATUS_CREATIVE_APPROVED,
    STATUS_CREATIVE_PENDING,
    STATUS_CAMPAIGN_PENDING,
    STATUS_DELETED,
    STATUS_DRAFT,
    STATUS_EXPIRED,
    STATUS_LIVE,
    STATUS_REQUESTED,
    STATUS_TESTING,
    STATUS_CAMPAIGN_REQUESTED,
    STATUS_PAUSED,
    STATUS_CLOSED
} from "../Status/Status";
//import { Slide, toast } from "react-toastify";
//import { ErrorMsg } from "../../../CreateCampaign/AlertComponent";
//import { ReactComponent as Error } from "../../../../../assets/icons/Close-Alert.svg";

//import async from "react-select/async";
import PopupDialog from "../../../../General/PopupDialog/PopupDialog";
import DiscardDialog from "../../../CreateCampaign/Dialog/DiscardDialog/DiscardDialog";
//import { act } from "react-dom/test-utils";
//import DiscardDialog from "../../../CreateCampaign/Dialog/DiscardDialog/DiscardDialog";
//import PublishDialog from "../../../CreateCampaign/Dialog/PublishDialog/PublishDialog";
//import InformationDialog from "../../../CreateCampaign/Dialog/InformationDialog/InformationDialog";
//import Button from "../../../../General/Button/Button";
//import Popup from "./popup";
//import Button from "../../../../General/Button/Button";

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable  @typescript-eslint/no-unused-vars */

const RowActionMenu = ({ action, id }) => {
    let campaignobj;
    const history = useHistory();
    const jwt = useAppSelector(state => state.authReducer.jwt);
    const userName = useAppSelector((state) => state.authReducer.userName);
    const { user, value, capability } = useContext(Context);
    const [role, setRole] = user;
    const [capabilities, setCapabilities] = capability;
    const [context, setContext] = value;
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [toBeCompleted, setToBeCompleted] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    var displayText;
    let status = action.status;

    useEffect(() => {
        localStorage.setItem("popup", "false")
        localStorage.setItem("deleteCampaignPopup", "false");
        localStorage.setItem("launchpopup", "false");
        localStorage.setItem("pausepopup", "false");
        localStorage.setItem("close_campaign", "false");
        const abortController = new AbortController();
        if (id !== undefined) {
            const getCampaignDetails = async () => {
                setIsLoaded(true);
                const url = new URL(
                    `${process.env.REACT_APP_API_HOSTNAME}v1/campaigns/${id}?version=${action.version}`
                );
                const callParams = {
                    method: "GET",
                    headers: {
                        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${jwt}`
                    }
                };
                const serverResponse = await fetch(url.toString(), callParams, { signal: abortController.signal });
                const serverResponseJson = serverResponse.json().then((response) => {
                    const res = response[0];
                    campaignobj = res;
                });
                setIsLoaded(false);
            };
            getCampaignDetails();
        }
        return () => {
            abortController.abort();
        };
    }, [id]);

    // const checkMissingItems = async () => {
    //      let itemsArray = [];
    //      let isItemsMissing = false;
    //      let text = "The following items are missing. Please review before asking for approval:";
    //      if (campaignobj) {
    //           if (campaignobj.channel && campaignobj.channel.length > 0) {
    //                itemsArray = [...itemsArray, campaignobj.channel];
    //                text += " \nChannel has to be selected, ";
    //           }
    //           if (campaignobj.emailTemplate && campaignobj.emailTemplate.length <= 0) {
    //                itemsArray = [...itemsArray, campaignobj.emailTemplate];
    //                text += " \nEach selected channle must have at least one selected template, ";
    //           }
    //           if (campaignobj.emailTemplateCopy && campaignobj.emailTemplateCopy.length <= 0) {
    //                itemsArray = [...itemsArray, campaignobj.emailTemplateCopy];
    //                text += " \nEach selected template must have at least one associated copy, ";
    //           }
    //           if (itemsArray.length > 0) {
    //                isItemsMissing = true;
    //                displayText = text + " \nAll creative approvals must be provided.";
    //           }
    //      }
    //      return isItemsMissing;
    // }

    const deleteCampaign = async (campaignId) => {
        const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/campaigns/${campaignId}`);
        const callParams = {
            method: "DELETE",
            headers: {
                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                "Content-Type": "application/json",
                "Authorization": `Bearer ${jwt}`
            }
        };
        const serverResponse = await fetch(url.toString(), callParams);
        const reloadPage = await serverResponse.then(window.location.reload());
    }

    // const updateStatus = async (campaignId, actionReq) => {
    //      const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/campaigns/${campaignId}`);
    //      campaignobj.comments = [];
    //      actionReq === "creative_request"
    //           ? (campaignobj.status = RoleStatus.Status_CreativeRequested[0].status)
    //           : actionReq === "cancel_creative_request"
    //                ? (campaignobj.status = RoleStatus.Status_CreativeCancelRequest[0].status)
    //                : actionReq === "deny_creative_approval"
    //                     ? campaignobj.status = RoleStatus.Status_CreativeDenied[0].status
    //                     : actionReq === "creative_approve"
    //                          ? campaignobj.status = RoleStatus.Status_CreativeApproved[0].status
    //                          : actionReq === "req_campaign_approval"
    //                               ? campaignobj.status = RoleStatus.Status_CampaignRequested[0].status
    //                               : actionReq === "campaign_approve"
    //                                    ? campaignobj.status = RoleStatus.Status_CampaignApproved[0].status
    //                                    : actionReq === "deny_campaign_approval"
    //                                         ? campaignobj.status = RoleStatus.Status_Draft[0].status
    //                                         : actionReq === "testing_approve"
    //                                              ? campaignobj.status = RoleStatus.Status_TestingApproved[0].status
    //                                              : actionReq === "testing_fail"
    //                                                   ? campaignobj.status = RoleStatus.Status_Draft[0].status
    //                                                   : actionReq === "launch"
    //                                                        ? campaignobj.status = RoleStatus.Status_Live[0].status
    //                                                        : actionReq === "cancel_campaign_request"
    //                                                             ? campaignobj.status = RoleStatus.Status_Draft[0].status
    //                                                             : actionReq === "campaign_pause"
    //                                                                  ? campaignobj.status = RoleStatus.Status_Pause[0].status
    //                                                                  : actionReq === "campaign_live"
    //                                                                       ? campaignobj.status = RoleStatus.Status_Live[0].status
    //                                                                       : (campaignobj.status = "Draft");

    //      const callParams = {
    //           method: "PUT",
    //           headers: {
    //                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
    //                "Content-Type": "application/json",
    //                "Authorization": `Bearer ${jwt}`
    //           },
    //           body: JSON.stringify(campaignobj)
    //      };
    //      //console.log(JSON.stringify(callParams))
    //      const serverResponse = await fetch(url.toString(), callParams);
    //      const mystatus = await serverResponse.json();
    //      return mystatus.status;
    // }

    const updateStatus = async (campaignId, actionReq) => {
        let actionItem;
        let obj = {
            modifiedBy: userName
        }

        if (actionReq === "creative_request") {
            actionItem = RoleStatus.Action_CreativeRequested[0].action;
        } else if (actionReq === "cancel_creative_request") {
            actionItem = RoleStatus.Action_CreativeCancelRequest[0].action;
        } else if (actionReq === "close_campaign") {
            actionItem = RoleStatus.Action_Closed[0].action;
        } else if (actionReq === "deny_creative_approval") {
            actionItem = RoleStatus.Action_CreativeDenied[0].action;
        } else if (actionReq === "creative_approve") {
            actionItem = RoleStatus.Action_CreativeApproved[0].action;
        } else if (actionReq === "req_campaign_approval") {
            actionItem = RoleStatus.Action_CampaignRequested[0].action;
        } else if (actionReq === "campaign_approve") {
            actionItem = RoleStatus.Action_CampaignApproved[0].action;
        } else if (actionReq === "deny_campaign_approval") {
            actionItem = RoleStatus.Action_CampaignDenied[0].action;
        } else if (actionReq === "testing_approve") {
            actionItem = RoleStatus.Action_TestingApproved[0].action;
        } else if (actionReq === "testing_fail") {
            actionItem = RoleStatus.Action_TestingFailed[0].action;
        } else if (actionReq === "launch") {
            actionItem = RoleStatus.Action_Launch[0].action;
        } else if (actionReq === "cancel_campaign_request") {
            actionItem = RoleStatus.Action_CampaignCancelRequest[0].action;
        } else if (actionReq === "campaign_pause") {
            actionItem = RoleStatus.Action_Pause[0].action;
        } else if (actionReq === "campaign_live") {
            actionItem = RoleStatus.Action_Launch[0].action;
        } else if (actionReq === "moveToDraft") {
            actionItem = RoleStatus.Action_MoveToDraft[0].action
        }

        const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/campaigns/${campaignId}/action/${actionItem}`);

        const callParams = {
            method: "PUT",
            headers: {
                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                "Content-Type": "application/json",
                "Authorization": `Bearer ${jwt}`
            },
            body: JSON.stringify(obj)
        };
        //console.log(JSON.stringify(callParams))
        const serverResponse = await fetch(url.toString(), callParams);
        const mystatus = await serverResponse.json();
        return mystatus.status;
    }

    const getContentDetails = async () => {
        let contentId;
        if (id !== undefined) {
            setIsLoaded(true);
            const url = new URL(
                //change it later with actual end point
                `${process.env.REACT_APP_API_HOSTNAME}v1/campaigns/${id}?version=${action.version}`
            );
            const callParams = {
                method: "GET",
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`
                }
            };
            const serverResponse = await fetch(url.toString(), callParams);
            const serverResponseJson = serverResponse.json().then((response) => {
                const res = response[0];
                campaignobj = res;
                contentId = "";  //fill content id which is recieved from api
            });
            setIsLoaded(false);
        }

        history.push(`/ctmp/content/edit-content/new-content/${contentId}`);
    }

    const actions = useMemo(() => {
        switch (status.toLowerCase()) {
            case STATUS_DRAFT:
                return [
                    capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW) ?
                        <ActionMenuItems action={action}
                            actionItem="view" /> : "",
                    <ActionMenuItems action={action}
                        actionItem="edit" />,
                    <div key="request approval" className={styles.MenuItem}>
                        <PopupDialog
                            renderPopupContent={(closeModal) => (
                                <DiscardDialog
                                    yesAction={async () => {
                                        closeModal()
                                    }}
                                    noAction={closeModal}
                                    className="Request Creative Approval"
                                    headerText="Request Creative Approval"
                                    bodyText={displayText}
                                />
                            )}
                            renderControlContent={(openModal) => (
                                <ul
                                    onClick={() => {
                                        //const isMissingItems = checkMissingItems();

                                        let itemsArray = [];
                                        let text = "";// = "The following items are missing. Please review before asking for approval: \n";

                                        if (campaignobj) {
                                            if (campaignobj.channel && campaignobj.channel.length <= 0) {
                                                itemsArray = [...itemsArray, campaignobj.channel];
                                                text += "Channel has to be selected, ";
                                            }
                                            if (campaignobj.emailTemplate && campaignobj.emailTemplate.length <= 0) {
                                                itemsArray = [...itemsArray, campaignobj.emailTemplate];
                                                text += "Each selected channle must have at least one selected template, ";
                                            }
                                            if (campaignobj.emailTemplateCopy && campaignobj.emailTemplateCopy.length <= 0) {
                                                itemsArray = [...itemsArray, campaignobj.emailTemplateCopy];
                                                text += "Each selected template must have at least one associated copy";
                                            }
                                        }
                                        if (itemsArray.length > 0) {
                                            displayText = text;
                                            openModal();
                                        } else {
                                            const statusVar = updateStatus(id, "creative_request");
                                            statusVar === "Campaign Details Updated Successfully"
                                                ? (setContext("creative_request"))
                                                : (setContext(""));
                                        }
                                    }}
                                >
                                    {status.toLowerCase() === STATUS_DRAFT ? "Request Creative Approval" : "Request Campaign Approval"}
                                </ul>
                            )}
                        />
                    </div>,
                    <div
                        key="preview"
                        onClick={() => {
                            history.push(`/ctmp/dashboard/preview/${campaignobj.emailTemplate[0].id}`);
                        }}
                        className={styles.MenuItem}
                    >
                        Preview
                    </div>,
                    <div
                        key="AddCreativeBrief"
                        onClick={() => {
                            /* { (isLoaded === true) ? <LoadSpinner /> : "" } */
                            getContentDetails();
                        }}
                        className={styles.MenuItem}
                    >
                        Add creative brief
                    </div>,
                    capabilities.includes(CapabilityConstants.CAMPAIGN_DELETE) ?
                        <ActionMenuItems action={action}
                            actionItem="delete" />
                        : ""
                ];
            case STATUS_REQUESTED:
                return [
                    capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW) ?
                        <ActionMenuItems action={action}
                            actionItem="view" /> : "",
                    <ActionMenuItems action={action}
                        actionItem="edit" />,
                    capabilities.includes(CapabilityConstants.CAMPAIGN_APPROVE) ?
                        <div onClick={async () => {
                            const statusVar = await updateStatus(id, "creative_approve");
                            console.log(statusVar);
                            statusVar === "Campaign Details Updated Successfully"
                                ? (setContext("creative_approve"))
                                : (setContext(""));

                        }}
                            key="creative_approve"
                            className={styles.MenuItem}>Approve
                        </div> : "",
                    capabilities.includes(CapabilityConstants.CAMPAIGN_DENY) ?
                        <div onClick={async () => {
                            const statusVar = await updateStatus(id, "deny_creative_approval");
                            console.log(statusVar);
                            statusVar === "Campaign Details Updated Successfully"
                                ? (setContext("deny_creative_approval"))
                                : (setContext(""));

                        }}
                            key="cancel approval"
                            className={styles.MenuItem}>Deny
                        </div> : "",
                    <div key="request approval"
                        onClick={async () => {
                            const statusVar = await updateStatus(id, "cancel_creative_request");
                            statusVar === "Campaign Details Updated Successfully"
                                ? (setContext("cancel_creative_request"))
                                : (setContext(""));

                        }}
                        className={styles.MenuItem}>{status.toLowerCase() === STATUS_DRAFT ? "Request Approval" : "Cancel Approval Request"}
                    </div>,
                    <div
                        key="AddCreativeBrief"
                        onClick={() => {
                            getContentDetails();
                        }}
                        className={styles.MenuItem}
                    >
                        Add creative brief
                    </div>,
                    capabilities.includes(CapabilityConstants.CAMPAIGN_DELETE) ?
                        <ActionMenuItems action={action} actionItem="delete" /> : ""
                ];
            case STATUS_CAMPAIGN_REQUESTED:
                return [
                    capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW) ?
                        <ActionMenuItems action={action} actionItem="view" /> : "",
                    <ActionMenuItems action={action} actionItem="edit" />,
                    <div key="request approval"
                        onClick={async () => {
                            const statusVar = await updateStatus(id, "cancel_campaign_request");
                            console.log(statusVar);
                            statusVar === "Campaign Details Updated Successfully"
                                ? (setContext("cancel_campaign_request"))
                                : (setContext(""));

                        }}
                        className={styles.MenuItem}> Cancel Approval Request
                    </div>,
                    <div
                        key="AddCreativeBrief"
                        onClick={() => {
                            getContentDetails();
                        }}
                        className={styles.MenuItem}
                    >
                        Add creative brief
                    </div>,
                    capabilities.includes(CapabilityConstants.CAMPAIGN_DELETE) ?
                        <ActionMenuItems action={action} actionItem="delete" /> : ""
                ];
            case STATUS_CREATIVE_PENDING:
                return [
                    capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW) ?
                        <ActionMenuItems action={action} actionItem="view" /> : "",
                    <div onClick={async () => {
                        const statusVar = await updateStatus(id, "creative_approve");
                        console.log(statusVar);
                        statusVar === "Campaign Details Updated Successfully"
                            ? (setContext("creative_approve"))
                            : (setContext(""));

                    }}
                        key="creative_approve"
                        className={styles.MenuItem}>Approve
                    </div>,
                    capabilities.includes(CapabilityConstants.CAMPAIGN_DENY) ?
                        <div onClick={async () => {
                            const statusVar = await updateStatus(id, "deny_creative_approval");
                            console.log(statusVar);
                            statusVar === "Campaign Details Updated Successfully"
                                ? (setContext("deny_creative_approval"))
                                : (setContext(""));

                        }}
                            key="cancel approval"
                            className={styles.MenuItem}>Deny
                        </div> : "",
                    <div
                        key="AddCreativeBrief"
                        onClick={() => {
                            getContentDetails();
                        }}
                        className={styles.MenuItem}
                    >
                        Add creative brief
                    </div>,
                    capabilities.includes(CapabilityConstants.CAMPAIGN_DELETE) ?
                        <ActionMenuItems action={action} actionItem="delete" /> : ""
                ];
            case STATUS_CAMPAIGN_PENDING:
                return [
                    capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW) ?
                        <ActionMenuItems action={action} actionItem="view" /> : "",
                    <ActionMenuItems action={action} actionItem="edit" />,
                    capabilities.includes(CapabilityConstants.CAMPAIGN_APPROVE) ?
                        <div onClick={async () => {
                            const statusVar = await updateStatus(id, "campaign_approve");
                            console.log(statusVar);
                            statusVar === "Campaign Details Updated Successfully"
                                ? (setContext("campaign_approve"))
                                : (setContext(""));
                        }}
                            key="cancel_campaign_approval"
                            className={styles.MenuItem}>Approve
                        </div> : "",
                    capabilities.includes(CapabilityConstants.CAMPAIGN_DENY) ?
                        <div onClick={async () => {
                            const statusVar = await updateStatus(id, "deny_campaign_approval");
                            console.log(statusVar);
                            statusVar === "Campaign Details Updated Successfully"
                                ? (setContext("deny_campaign_approval"))
                                : (setContext(""));

                        }}
                            key="cancel approval"
                            className={styles.MenuItem}>Deny
                        </div> : "",
                    <div key="request approval"
                        onClick={async () => {
                            const statusVar = await updateStatus(id, "cancel_campaign_request");
                            console.log(statusVar);
                            statusVar === "Campaign Details Updated Successfully"
                                ? (setContext("cancel_campaign_request"))
                                : (setContext(""));

                        }}
                        className={styles.MenuItem}> Cancel Approval Request
                    </div>,
                    <div
                        key="AddCreativeBrief"
                        onClick={() => {
                            getContentDetails();
                        }}
                        className={styles.MenuItem}
                    >
                        Add creative brief
                    </div>,
                    capabilities.includes(CapabilityConstants.CAMPAIGN_DELETE) ?
                        <ActionMenuItems action={action} actionItem="delete" /> : ""
                ];
            case STATUS_TESTING:
                return [
                    capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW) ?
                        <ActionMenuItems action={action} actionItem="view" /> : "",
                    <ActionMenuItems action={action} actionItem="edit" />,
                    role === RoleConstants.SUPERADMIN ?
                        <div onClick={async () => {
                            let itemsToBeFilledArray = [];
                            let itemsToBeFilled = [];

                            if (campaignobj.isCreativeApproval === true && campaignobj.creativeApprovalFileInfo === null) {
                                itemsToBeFilledArray = [...itemsToBeFilledArray, campaignobj.creativeApprovalFileInfo];
                                itemsToBeFilled = [...itemsToBeFilled, "Creative Approval File has to be uploaded"]
                            }
                            if (campaignobj.isDivisionApproval === true && campaignobj.divisionApprovalFileInfo === null) {
                                itemsToBeFilledArray = [...itemsToBeFilledArray, campaignobj.divisionApprovalFileInfo];
                                itemsToBeFilled = [...itemsToBeFilled, "Division Approval File has to be uploaded"]
                            }
                            if (campaignobj.isLegalApproval === true && campaignobj.legalApprovalFileInfo === null) {
                                itemsToBeFilledArray = [...itemsToBeFilledArray, campaignobj.legalApprovalFileInfo];
                                itemsToBeFilled = [...itemsToBeFilled, "Legal Approval File has to be uploaded"]
                            }

                            if (itemsToBeFilledArray.length > 0) {
                                setToBeCompleted(itemsToBeFilled)
                                setShowFilterModal(true);
                                localStorage.setItem("popup", "true")
                            } else {
                                const statusVar = await updateStatus(id, "testing_approve");
                                console.log(statusVar);
                                statusVar === "Campaign Details Updated Successfully"
                                    ? (setContext("testing_approve"))
                                    : (setContext(""));
                            }
                        }}
                            key="approve testing"
                            className={styles.MenuItem}>Approve Testing
                        </div> : "",
                    <div
                        key="AddCreativeBrief"
                        onClick={() => {
                            getContentDetails();
                        }}
                        className={styles.MenuItem}
                    >
                        Add creative brief
                    </div>,
                    <div key="fail testing" className={styles.MenuItem}
                        onClick={async () => {
                            const statusVar = await updateStatus(id, "testing_fail");
                            console.log(statusVar);
                            statusVar === "Campaign Details Updated Successfully"
                                ? (setContext("testing_fail"))
                                : (setContext(""));
                        }}>Fail Testing</div>
                ];
            case STATUS_APPROVED:
                return [
                    capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW) ?
                        <ActionMenuItems action={action} actionItem="view" /> : "",

                    <PopupDialog
                        key={"launch" + id}
                        height="30rem"
                        width="67rem"
                        renderPopupContent={(closeModal) => (
                            <DiscardDialog
                                yesAction={async () => {
                                    localStorage.setItem("launchpopup", "false")
                                    const statusVar = await updateStatus(id, "launch");
                                    console.log(statusVar);
                                    statusVar === "Campaign Details Updated Successfully"
                                        ? (setContext("launch"))
                                        : (setContext(""));
                                }}
                                noAction={() => { closeModal(); localStorage.setItem("launchpopup", "false") }}
                                className="Launch Campaign"
                                headerText="Launch Campaign"
                                visiblity="true"
                            />
                        )}
                        renderControlContent={(openModal) => (
                            capabilities.includes(CapabilityConstants.CAMPAIGN_LAUNCH) ?
                                <div
                                    key="launch"
                                    onClick={() => { openModal(); localStorage.setItem("launchpopup", "true") }}
                                    className={styles.MenuItem}
                                >
                                    Launch
                                </div> : ""
                        )}
                    />,
                    // <div key="launch" onClick={async () => {
                    //      const statusVar = await updateStatus(id, "launch");
                    //      console.log(statusVar);
                    //      statusVar === "Campaign Details Updated Successfully"
                    //           ? (setContext("launch"))
                    //           : (setContext(""));
                    // }}
                    //      className={styles.MenuItem}>Launch</div> : "",
                    <div
                        key="AddCreativeBrief"
                        onClick={() => {
                            getContentDetails();
                        }}
                        className={styles.MenuItem}
                    >
                        Add creative brief
                    </div>,
                    capabilities.includes(CapabilityConstants.CAMPAIGN_DELETE) ?
                        <ActionMenuItems action={action} actionItem="delete" /> : "",
                    <div key="priority" className={styles.MenuItem}
                        onClick={() => {
                            window.location.assign(`/ctmp/dashboard/campaign-priority`)
                        }}>Priority</div>

                ];
            case STATUS_CREATIVE_APPROVED:
                return [
                    capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW) ?
                        <ActionMenuItems action={action} actionItem="view" /> : "",
                    <ActionMenuItems action={action} actionItem="edit" />,
                    <div onClick={async () => {
                        const statusVar = await updateStatus(id, "req_campaign_approval");
                        console.log(statusVar);
                        statusVar === "Campaign Details Updated Successfully"
                            ? (setContext("req_campaign_approval"))
                            : (setContext(""));

                    }}
                        key="campaign_approval" className={styles.MenuItem}>Request Campaign Approval
                    </div>,
                    <div
                        key="AddCreativeBrief"
                        onClick={() => {
                            getContentDetails();
                        }}
                        className={styles.MenuItem}
                    >
                        Add creative brief
                    </div>,
                    capabilities.includes(CapabilityConstants.CAMPAIGN_DELETE) ?
                        <ActionMenuItems action={action} actionItem="delete" /> : ""
                ];
            case STATUS_LIVE:
                return [
                    capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW) ?
                        <ActionMenuItems action={action} actionItem="view" /> : "",
                    <div
                        key="AddCreativeBrief"
                        onClick={() => {
                            getContentDetails();
                        }}
                        className={styles.MenuItem}
                    >
                        Add creative brief
                    </div>,
                    <PopupDialog
                        key={"launch" + id}
                        height="30rem"
                        width="67rem"
                        renderPopupContent={(closeModal) => (
                            <DiscardDialog
                                yesAction={async () => {
                                    localStorage.setItem("pausepopup", "false");
                                    const statusVar = await updateStatus(id, "campaign_pause");
                                    console.log(statusVar);
                                    statusVar === "Campaign Details Updated Successfully"
                                        ? (setContext("campaign_pause"))
                                        : (setContext(""));
                                }}
                                noAction={() => { closeModal(); localStorage.setItem("pausepopup", "false") }}
                                className="Pause Campaign"
                                headerText="Pause Campaign"
                            />
                        )}
                        renderControlContent={(openModal) => (
                            capabilities.includes(CapabilityConstants.CAMPAIGN_PAUSE) ?
                                <div
                                    key="pause"
                                    onClick={() => { openModal(); localStorage.setItem("pausepopup", "true"); }}
                                    className={styles.MenuItem}
                                >
                                    Pause
                                </div> : ""
                        )}
                    />
                    // capabilities.includes(CapabilityConstants.CAMPAIGN_PAUSE) ?
                    //      <div onClick={async () => {
                    //           const statusVar = await updateStatus(id, "campaign_pause");
                    //           console.log(statusVar);
                    //           statusVar === "Campaign Details Updated Successfully"
                    //                ? (setContext("campaign_pause"))
                    //                : (setContext(""));

                    //      }}
                    //           key="pause" className={styles.MenuItem}>Pause
                    //      </div> : ""

                    // <div key="edit throttle" className={styles.MenuItem}>Edit
                    //      Throttle</div>
                ];
            case STATUS_PAUSED:
                return [
                    capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW) ?
                        <ActionMenuItems action={action} actionItem="view" /> : "",
                    <ActionMenuItems action={action} actionItem="edit" />,
                    // <div onClick={async () => {

                    //      const statusVar = await updateStatus(id, "campaign_live");
                    //      console.log(statusVar);
                    //      statusVar === "Campaign Details Updated Successfully"
                    //           ? (setContext("campaign_live"))
                    //           : (setContext(""));
                    // }}
                    //      key="live" className={styles.MenuItem}>Launch
                    // </div> : "",
                    <div
                        key="AddCreativeBrief"
                        onClick={() => {
                            getContentDetails();
                        }}
                        className={styles.MenuItem}
                    >
                        Add creative brief
                    </div>,
                    <PopupDialog
                        key={"launch" + id}
                        height="30rem"
                        width="67rem"
                        renderPopupContent={(closeModal) => (
                            <DiscardDialog
                                yesAction={async () => {
                                    localStorage.setItem("launchpopup", "false")
                                    const statusVar = await updateStatus(id, "campaign_live");

                                    console.log(statusVar);
                                    statusVar === "Campaign Details Updated Successfully"
                                        ? (setContext("campaign_live"))
                                        : (setContext(""));
                                }}
                                noAction={() => { closeModal(); localStorage.setItem("launchpopup", "false") }}
                                className="Launch Campaign"
                                headerText="Launch Campaign"
                                visiblity="true"
                            />
                        )}
                        renderControlContent={(openModal) => (
                            capabilities.includes(CapabilityConstants.CAMPAIGN_LAUNCH) ?
                                <div
                                    key="launch"
                                    onClick={() => { openModal(); localStorage.setItem("launchpopup", "true") }}
                                    className={styles.MenuItem}
                                >
                                    Launch
                                </div> : ""
                        )}
                    />,

                    // <div key="edit throttle" className={styles.MenuItem}>Edit
                    //      Throttle</div>
                    <div key="moveToDraft" className={styles.MenuItem}
                        onClick={async () => {
                            const statusVar = await updateStatus(id, "moveToDraft");
                            console.log(statusVar);
                            statusVar === "Campaign Details Updated Successfully"
                                ? (setContext("moveToDraft"))
                                : (setContext(""));
                        }}
                    >
                        Move to Draft</div>,

                    <PopupDialog
                        key={"close_campaign" + id}
                        height="30rem"
                        width="67rem"
                        renderPopupContent={(closeModal) => (
                            <DiscardDialog
                                yesAction={async () => {
                                    localStorage.setItem("close_campaign", "false")
                                    const statusVar = await updateStatus(id, "close_campaign");

                                    console.log(statusVar);
                                    statusVar === "Campaign Details Updated Successfully"
                                        ? (setContext("close_campaign"))
                                        : (setContext(""));
                                }}
                                noAction={() => { closeModal(); localStorage.setItem("close_campaign", "false") }}
                                className="close_campaign"
                                headerText="Close Campaign"
                                visiblity="true"
                            />
                        )}
                        renderControlContent={(openModal) => (
                            // capabilities.includes(CapabilityConstants.CAMPAIGN_LAUNCH) ?
                            <div
                                key="close"
                                onClick={() => { openModal(); localStorage.setItem("close_campaign", "true") }}
                                className={styles.MenuItem}
                            >
                                Close
                            </div>
                        )}
                    />
                ];
            case STATUS_EXPIRED:
                return [
                    capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW) ?
                        <ActionMenuItems action={action} actionItem="view" /> : "",
                    <ActionMenuItems action={action} actionItem="edit" />,
                    <div
                        key="AddCreativeBrief"
                        onClick={() => {
                            getContentDetails();
                        }}
                        className={styles.MenuItem}
                    >
                        Add creative brief
                    </div>,
                    capabilities.includes(CapabilityConstants.CAMPAIGN_DELETE) ?
                        <ActionMenuItems action={action} actionItem="delete" /> : ""
                ];
            case STATUS_DELETED:
            case STATUS_CLOSED:
                return [
                    capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW) ?
                        <ActionMenuItems action={action} actionItem="view" /> : "",
                    <div
                        key="AddCreativeBrief"
                        onClick={() => {
                            getContentDetails();
                        }}
                        className={styles.MenuItem}
                    >
                        Add creative brief
                    </div>
                ];
            default:
                return [<div key="na" className={styles.MenuItem}>N/A</div>];
        }

    }, [action]);

    return <div>
        <div className={styles.MenuContainer}>
            {actions}
        </div>
        <ReactModal
            shouldCloseOnOverlayClick={true}
            isOpen={showFilterModal}
            onRequestClose={() => { setShowFilterModal(false); localStorage.setItem("popup", "false") }}
            className={styles.FilterModal}
            overlayClassName={styles.FilterModalOverlay}
        >
            <div className={styles.FilterTitleContainer}>
                <div className={styles.FilterTitleHeading}>
                    <p>
                        <span>Items to be filled</span>
                    </p>
                    <CloseX
                        onClick={() => { setShowFilterModal(false); localStorage.setItem("popup", "false"); }}
                        className={styles.CloseIcon}
                    />
                </div>
            </div>
            <div className={styles.FiltersContainer}>
                <p style={{ fontSize: "1.6rem" }}> The following items are missing. Please review approval options: </p>
                <p>{toBeCompleted ? toBeCompleted.map((x) => <li>{x}</li>) : ""}</p>
            </div>
        </ReactModal>
    </div>;
};

export default RowActionMenu;