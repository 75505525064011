import styles from "./DiscardDialog.module.css";
import { ReactComponent as CloseX } from "../../../../../assets/icons/Close.svg";
import { ReactComponent as CloseAlertX } from "../../../../../assets/icons/Close-Alert.svg";
import { ReactComponent as WarningTriangle } from "../../../../../assets/icons/warning-triangle.svg";
import SaveAndDiscardButtonSet
    from "../../../../General/SaveAndDiscardButtonSet/SaveAndDiscardButtonSet";
import Button from "../../../../General/Button/Button";
import React from "react";
import LabeledSelect from "../../../../General/LabeledSelect/LabeledSelect";

type DiscardDialogProps = {
    className?: string
    bodyText?: string
    headerText?: string
    yesAction: () => void
    noAction: () => void
}

const DiscardDialog = ({ className, yesAction, noAction, bodyText, headerText }: DiscardDialogProps) => {
    return <div className={[className, styles.Container].join(" ")}>
        <div className={styles.Header}>
            {className === "Request Creative Approval" ?
                <CloseAlertX /> : null}

            {(headerText !== "" || headerText !== null || headerText !== undefined) && <p>{headerText}</p>}
            {(headerText === "" || headerText === null || headerText === undefined) && <p><span>Discard Message</span></p>}

            <CloseX onClick={noAction} className={styles.CloseIcon} />
        </div>
        {className === "DeleteCampaign" ?
            <div className={styles.Body}>
                <WarningTriangle />
                <p>Are you sure you want to delete this Campaign ?</p>
            </div> :
            className === "Pause Campaign" ?
            <div className={styles.Body}>
            <WarningTriangle />
            <p>Are you sure you want to pause this Campaign ?</p>
           </div> :
            className === "Launch Campaign" ?
            <div className={styles.Body}>
            <WarningTriangle />
            <p>Are you sure you want to launch this Campaign ?</p>
         </div> :
           className === "close_campaign" ?
           <div className={styles.Body}>
           <WarningTriangle />
           <p>Are you sure you want to close this Campaign ?</p>
        </div> :
            className === "Request Creative Approval" ?
                <div className={styles.Body2}>
                    {(bodyText) ?
                        <div>
                            <p className={styles.bold}> The following items are missing. Please review before asking for approval: </p>
                            <p>{bodyText}</p>
                            <p>All creative approvals must be provided</p>
                        </div> : null
                    }
                </div> : className === "Priority" ?
                    <p>
                        <div className={styles.PriorityCombo}>
                            <div>
                                <LabeledSelect
                                    className={styles.CampaignCopy}
                                    //optionsUrl="v1/ctmp/category"
                                    title={
                                        <>
                                            Assign a priority level to this campaign. Select a new priority level from the dropdown.<span></span>
                                        </>
                                    }
                                    onValueChange={(option) => console.log()}
                                />
                            </div>
                        </div>
                    </p> :
                    <div className={styles.Body}>
                        <WarningTriangle />
                        {(bodyText !== "" || bodyText !== null || bodyText !== undefined) && <p>{bodyText}</p>}
                        {(bodyText === "" || bodyText === null || bodyText === undefined) && <p>Are you sure you want to discard the changes without saving?</p>}
                    </div>
        }
        {className === "Request Creative Approval" ?
            <Button
                onClick={yesAction}
                name="Request Creative Approval"
            />
            : className === "Priority" ?
                <SaveAndDiscardButtonSet
                    yesAction={yesAction}
                    noAction={noAction}
                    yesTitle="Confirm"
                    noTitle="Back"
                    className={styles.Buttons}
                /> :
                <SaveAndDiscardButtonSet yesAction={yesAction}
                    noAction={noAction}
                    className={styles.Buttons} />}
    </div>
};

export default DiscardDialog;