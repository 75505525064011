import React from "react";
import RowAction from "../../General/Table/RowAction/RowAction";
//import CategoryCell from "./Row/Category/CategoryCell";
import ChannelCell from "./Row/Channel/ChannelCell";
import RowActionMenu from "./Row/RowActionMenu/RowActionMenu";
import Status from "./Row/Status/Status";
import ContentDetails from "./Row/ContentDetails/ContentDetails";
import styles from "./ManageContentTable.module.css";

export const columns = [
  {
    Header: "Action",
    accessor: "action",
    Cell: ({ cell: { value } }) => (
      <RowAction
        menu={<RowActionMenu action={value} id={value.contentId} />}
      />
    )
  },
  {
    Header: "Channel",
    accessor: "channel",
    Cell: ({ cell: { value } }) => <ChannelCell data={value} />
  },
  {
    Header: "Content Details",
    accessor: "contentDetails",
    Cell: ({ cell: { value } }) => <ContentDetails data={value} />
  },
  {
    Header: "Associated Campaigns",
    accessor: "associatedCampaigns",
    /* Cell: ({ cell: { value } }) => <CategoryCell data={value} /> */
    Cell: ({ cell: { value } }) => value.associatedCampaign.length !== 0 ? <li className={styles.AssociatedCampaigns}> {value.associatedCampaign} </li> : ""
  },
  {
    Header: "Associated Templates",
    accessor: "associatedTemplates",
    Cell: ({ cell: { value } }) => <li className={styles.AssociatedTemplates}> {value} </li>
  },
  {
    Header: "Created by",
    accessor: "creator",
    Cell: ({ cell: { value } }) => value
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ cell: { value } }) => <Status value={value} />
  }
];
