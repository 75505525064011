import React from "react";
import styles from "./LabeledInput.module.css";

type LabeledInputProps = {
  title?: any;
  component_status?: any;
  className?: any;
  onValueChange?: any;
  placeholder?: string;
  data?: any;
};

const LabeledInput = ({
  title,
  className,
  onValueChange,
  data,
  component_status,
  placeholder
}: LabeledInputProps) => {
  return (
    <div className={[styles.Container, className].join(" ")}>
      <label style={{ opacity: "none" }}>{title}</label>
      <input
        className={styles.Input}
        maxLength={className === "VINNumber" ? 17 :
          className === "DiscardDialog_NumberOfDays__38FPy" ? 3 :
          placeholder === "Add Sub Category" ? 100 : null}
        type="text"
        disabled={component_status}
        value={data ? data : undefined}
        placeholder={placeholder}
        onChange={(event) => onValueChange(event.target.value)}
      />
    </div>
  );
};

export default LabeledInput;
