import React, { useMemo } from "react";

import styles from "./Status.module.css";

export const STATUS_DRAFT = "draft";
export const STATUS_CREATIVE_PENDING = "creative approval pending/requested";
export const STATUS_CAMPAIGN_PENDING = "campaign approval requested";
export const STATUS_REQUESTED = "creative requested";
export const STATUS_CAMPAIGN_REQUESTED = "campaign requested";
export const STATUS_TESTING = "testing";
export const STATUS_APPROVED = "approved";
export const STATUS_CREATIVE_APPROVED = "creative approved";
export const STATUS_LIVE = "live";
export const STATUS_EXPIRED = "expired";
export const STATUS_DELETED = "deleted";
export const STATUS_PAUSED = "paused";
export const STATUS_REUSED = "Reused";
export const STATUS_CLOSED = "closed";

const Status = ({ value }) => {
    const styleColor = useMemo(() => {
        switch (value.toLowerCase()) {
            case STATUS_DRAFT:
                return styles.Blue;
            case STATUS_CLOSED:
                return styles.Grey;
            case STATUS_CAMPAIGN_REQUESTED:
                return styles.Blue;
            case STATUS_CREATIVE_PENDING:
            case STATUS_CAMPAIGN_PENDING:
                return styles.Yellow;
            case STATUS_REQUESTED:
            case STATUS_TESTING:
                return styles.Orange;
            case STATUS_APPROVED:
            case STATUS_CREATIVE_APPROVED:
            case STATUS_LIVE:
                return styles.Green;
            case STATUS_EXPIRED:
            case STATUS_DELETED:
            default:
                return styles.Red;
        }
    }, [value]);
    return <div className={ [styles.Status, styleColor].join(" ") }>
        { value }
    </div>;
};


export default Status;