import styles from "../../General/LabeledCardTemplateAndCopy/LabeledCardTemplateAndCopy.module.css";
import PopupDialog from "../../General/PopupDialog/PopupDialog";
import DiscardDialog from "../../ManageTemplate/CreateTemplate/DiscardTemplate/DiscardTemplate";

const CreateSubCategory = ({ title }) => {
  return (
    <div
      className={styles.Container}>
      <div className={styles.SubCategoryArea}>
        <PopupDialog
          height="30rem"
          width="90rem"
          renderPopupContent={(closeModal) => (
            <DiscardDialog
              yesAction={async (category, subCategory, isDuplicateSubCat) => {
                console.log("category - " + JSON.stringify(category) + " subCategory - " + subCategory +
                  " isDuplicateSubCat - " + isDuplicateSubCat)
                if (isDuplicateSubCat) {
                  console.log("show duplicate message")
                } else {
                  closeModal()
                }
              }}
              noAction={closeModal}
              className="Add Sub Category"
              visiblity="true"
            />
          )}
          renderControlContent={(openModal) =>
            <label style={{ color: "var(--ct-blue-secondary)", cursor: "pointer" }} onClick={openModal}>{title}</label>
          }
        />
      </div>
    </div>
  );
};

export default CreateSubCategory;
