import React, { useEffect, useState } from "react";
// import { useAppSelector } from "../../../redux/store";
import { Route, Switch } from "react-router-dom";
import styles from "./CreateCustomer.module.css";
import TitleWithPathHeader from "../../General/TitleWithPathHeader/TitleWithPathHeader";
import { useHistory } from "react-router-dom";
import VinList from "./CreateCustomerRight/VinList";
import ReuseList from "./CreateCustomerRight/ReuseList";
import CloneList from "./CreateCustomerRight/CloneList";
import EditList from "./CreateCustomerRight/EditList";
import CreateList from "./CreateCustomerRight/CreateList";
import PopupDialog from "../../General/PopupDialog/PopupDialog";
import DiscardDialog from "./CreateCustomerRight/DiscardCustomer/DiscardCustomer";

const CreateCustomer = (props) => {
  //   const jwt = useAppSelector((state) => state.authReducer.jwt);
  const history = useHistory();
  const pathName = history.location.pathname.split("/");
  const [navSelected, setNavSelected] = useState(pathName[4]);
  const [count, setCount] = useState(0);
  const [text, setText] = useState();
  const [editCustomerList, setEditCustomerList] = useState(false);
  const [viewCustomerList, setViewCustomerList] = useState(false);

  //set view and edit customerlist based on path
  useEffect(() => {
    if (props) {
      if (pathName[4] === "edit") {
        setEditCustomerList(true);
      } else if (pathName[4] === "view") {
        setViewCustomerList(true);
      }
    }
  }, []);

  return (
    <React.Fragment>
      <div className={styles.CreateCustomer}>
        {props && editCustomerList ? (
          <TitleWithPathHeader
            title="Edit Customer List"
            path={" Manage Customer List / Edit Customer List"}
          />
        ) : props && viewCustomerList ? (
          <TitleWithPathHeader
            title="View Customer List"
            path={" Manage Customer List / View Customer List"}
          />
        ) : (
          <TitleWithPathHeader
            title="Create Customer List"
            path={" Manage Customer List / Create Customer List"}
          />
        )}

        <div className={(viewCustomerList || editCustomerList) ? "" : styles.CreateCustomerModule}>
          {/* LEFT */}
          {(viewCustomerList || editCustomerList) ?
            "" :
            <div className={styles.LeftNav}>
              {/* CREATE */}
              <PopupDialog
                height="30rem"
                width="67rem"
                renderPopupContent={(closeModal) => (
                  <DiscardDialog
                    yesAction={() => {
                      history.push(
                        "/ctmp/customer-list/create-customer/create/vehicle-details"
                      );
                      setNavSelected("create");
                      closeModal();
                    }}
                    noAction={closeModal}
                    className="Discard"
                  />
                )}
                renderControlContent={(openModal) => (
                  <ul
                    className={
                      navSelected === "create"
                        ? styles.NavItemSelected
                        : navSelected === "edit" || navSelected === "view"
                          ? styles.NavItemDisabled
                          : styles.NavItem
                    }
                    onClick={() => {
                      if (count < 3 && text === "clone") {
                        openModal();
                      } else if (count > 0 && text === "reuse") {
                        openModal();
                      } else if (count < 3 && text === "import") {
                        openModal();
                      } else {
                        setNavSelected("create");
                        history.push(
                          "/ctmp/customer-list/create-customer/create/vehicle-details"
                        );
                      }
                    }}
                  >
                    Create New List
                  </ul>
                )} />
              {/* EDIT */}
              <ul
                className={
                  navSelected === "edit"
                    ? styles.NavItemSelected
                    : navSelected === "view"
                      ? styles.NavItemSelected
                      : styles.NavItemDisabled
                }
              // onClick={() => {
              //   navSelected === "edit"
              //     ? setNavSelected("edit")
              //     : setNavSelected("view");
              //   history.push(
              //     "/ctmp/customer-list/create-customer/edit/vehicle-details"
              //   );
              // }}
              >
                {navSelected === "view" ? "View Draft List" :
                  pathName[5] === "vin-list" ? "Edit Vin List" : "Edit Draft List"
                }
              </ul>
              {/* ClONE */}
              <PopupDialog
                height="30rem"
                width="67rem"
                renderPopupContent={(closeModal) => (
                  <DiscardDialog
                    yesAction={() => {
                      history.push(
                        "/ctmp/customer-list/create-customer/clone/vehicle-details"
                      );
                      setNavSelected("clone");
                      closeModal();
                    }}
                    noAction={closeModal}
                    className="Discard"
                  />
                )}
                renderControlContent={(openModal) => (
                  <ul
                    className={
                      navSelected === "clone"
                        ? styles.NavItemSelected
                        : navSelected === "edit" || navSelected === "view"
                          ? styles.NavItemDisabled
                          : styles.NavItem
                    }
                    onClick={() => {
                      if (count < 3 && text === "create") {
                        openModal();
                      } else if (count > 0 && text === "reuse") {
                        openModal();
                      } else if (count < 3 && text === "import") {
                        openModal();
                      } else {
                        setNavSelected("clone");
                        history.push(
                          "/ctmp/customer-list/create-customer/clone/vehicle-details"
                        );
                      }
                    }}
                  >
                    Clone Customer List
                  </ul>
                )}
              />
              {/* REUSE */}
              {/*<PopupDialog
              height="30rem"
              width="67rem"
              renderPopupContent={(closeModal) => (
                <DiscardDialog
                  yesAction={() => {
                    history.push(
                      "/ctmp/customer-list/create-customer/re-use/vehicle-details"
                    );
                    setNavSelected("re-use");
                    closeModal();
                  }}
                  noAction={closeModal}
                  className="Discard"
                />
              )}
              renderControlContent={(openModal) => (
                <ul
                  className={
                    navSelected === "re-use"
                      ? styles.NavItemSelected
                      : navSelected === "edit" || navSelected === "view"
                        ? styles.NavItemDisabled
                        : styles.NavItem
                  }
                  onClick={() => {
                    if (count < 3 && text === "create") {
                        openModal();
                    } else if (count < 3 && text === "clone") {
                        openModal();
                    } else if (count < 3 && text === "import") {
                        openModal();
                    } else {
                      setNavSelected("re-use");
                      history.push(
                        "/ctmp/customer-list/create-customer/re-use/vehicle-details"
                      );
                    }
                  }}
                >
                  Re-Use Published List
                </ul>
              )} />*/}
              {/* VIN-Import */}
              <PopupDialog
                height="30rem"
                width="67rem"
                renderPopupContent={(closeModal) => (
                  <DiscardDialog
                    yesAction={() => {
                      history.push("/ctmp/customer-list/create-customer/vin-list");
                      setNavSelected("vin-list");
                      closeModal();
                    }}
                    noAction={closeModal}
                    className="Discard"
                  />
                )}
                renderControlContent={(openModal) => (
                  <ul
                    className={
                      navSelected === "vin-list"
                        ? styles.NavItemSelected
                        : navSelected === "edit" || navSelected === "view"
                          ? styles.NavItemDisabled
                          : styles.NavItem
                    }
                    onClick={() => {
                      if (count < 3 && text === "create") {
                        openModal();
                      } else if (count > 0 && text === "reuse") {
                        openModal();
                      } else if (count < 3 && text === "clone") {
                        openModal();
                      } else {
                        setNavSelected("vin-list");
                        history.push(
                          "/ctmp/customer-list/create-customer/vin-list"
                        );
                      }
                    }}
                  >
                    Import Vin List
                  </ul>
                )} />
            </div>}

          {/* RIGHT */}
          <div className={(viewCustomerList || editCustomerList) ? styles.EditCustomerModule1 : styles.RightContent}>
            <div className={styles.CreateCustomerContent}>
              <Switch>
                <Route
                  path="/ctmp/customer-list/create-customer/vin-list"
                  render={() => (
                    <React.Fragment>
                      <VinList
                        id={pathName[6]}
                        navSelected={"vin-list"}
                        itemsEntered={(count, text) => {
                          console.log(
                            "count in import----->" + count + "----" + text
                          );
                          setText(text);
                          setCount(count);
                        }}
                      />
                    </React.Fragment>
                  )}
                />
                <Route
                  path="/ctmp/customer-list/create-customer/view/vin-list/:id"
                  render={(match) => (
                    <React.Fragment>
                      <VinList
                        id={pathName[6]}
                        navSelected={"vin-list"}
                        itemsEntered={(count, text) => {
                          console.log(
                            "count in import----->" + count + "----" + text
                          );
                          setText(text);
                          setCount(count);
                        }}
                      />
                    </React.Fragment>
                  )}
                />
                <Route
                  path="/ctmp/customer-list/create-customer/edit/vin-list/:id"
                  render={(match) => (
                    <React.Fragment>
                      <VinList
                        id={pathName[6]}
                        navSelected={"vin-list"}
                        itemsEntered={(count, text) => {
                          console.log(
                            "count in import----->" + count + "----" + text
                          );
                          setText(text);
                          setCount(count);
                        }}
                      />
                    </React.Fragment>
                  )}
                />
                <Route
                  path="/ctmp/customer-list/create-customer/re-use"
                  render={() => (
                    <React.Fragment>
                      <ReuseList
                        navSelected={"re-use"}
                        itemsEntered={(count, text) => {
                          console.log("count in reuse----->" + count);
                          setCount(count);
                          setText(text);
                        }}
                      />
                    </React.Fragment>
                  )}
                />
                <Route
                  path="/ctmp/customer-list/create-customer/clone"
                  render={() => (
                    <React.Fragment>
                      <CloneList
                        navSelected={"clone"}
                        itemsEntered={(count, text) => {
                          console.log("count in clone----->" + count);
                          setCount(count);
                          setText(text);
                        }}
                      />
                    </React.Fragment>
                  )}
                />
                <Route
                  path="/ctmp/customer-list/create-customer/edit/"
                  render={() => (
                    <React.Fragment>
                      <EditList navSelected={"edit"} id={pathName[6]} />
                    </React.Fragment>
                  )}
                />
                <Route
                  path="/ctmp/customer-list/create-customer/view/"
                  render={() => (
                    <React.Fragment>
                      <EditList navSelected={"view"} id={pathName[6]} />
                    </React.Fragment>
                  )}
                />
                <Route
                  path="/ctmp/customer-list/create-customer/create"
                  render={() => (
                    <React.Fragment>
                      <CreateList
                        navSelected={"create"}
                        itemsEntered={(count, text) => {
                          console.log(
                            "count in create----->" + count + "----" + text
                          );
                          setText(text);
                          setCount(count);
                        }}
                      />
                    </React.Fragment>
                  )}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateCustomer;
